import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name:"fecha"
})
export class FechaPipe implements PipeTransform{
  transform(value:string, limite:string) : string{
    let limit = parseInt(limite);
    //console.log(value.length > limit ? value.substring(0,limit) :   value);
    return value.length > limit ? value.substring(0,limit) :   value;
    
  }
}