import { Component, OnInit }        from '@angular/core';
import {DataGlobalService}          from '../../services/data-global.service';

@Component({
  selector: 'app-versiones',
  templateUrl: './versiones.component.html',
  styleUrls: ['./versiones.component.css']
})
export class VersionesComponent implements OnInit {

  constructor(
    private dataGlobalService: DataGlobalService
  ) { }

  ngOnInit() {
    this.dataGlobalService.versionesPortal();
  }

}
