import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cc',
  templateUrl: './cc.component.html',
  styleUrls: ['./cc.component.css']
})
export class CcComponent implements OnInit {
  public password;
  public array_resumen:any = [];
  public cc_compartir = "cc_sicomparte";
  public cc_comercial = "cc_sicomercial";
  public cc_sicondicion = "cc_sicondicion";
  public titulo_cc = "Licencia Creative Commons Atribución 4.0 Internacional";
  public url_cc = "https://creativecommons.org/licenses/by/4.0/deed.es";
  public descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
  public img_licencia = "../../../assets/img/seal.png";
  public img_cc = "../../../assets/img/ccby40.png";
  public img_userby = "../../../assets/img/userby.png"
  public by = "El crédito debe ser otorgado a usted, el creador.";  
  public sa = "";
  public img_sa = "../../../assets/img/sa.png";
  public nd = "";
  public img_nd = "../../../assets/img/nd.png";
  public nc = "";
  public img_nc = "../../../assets/img/ccbync40.png";
 
  @Input() array_di: any = {}

  @Output() onCcChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    /* this.preselectDefaultLicense(); */
  }
  ngDoCheck(){
    if(this.array_di.licencia){
      if(this.array_di.licencia.creative_commons == "Atribución 4.0 Internacional (CC BY 4.0)"){
        this.cc_compartir = "cc_sicomparte";
        this.cc_comercial = "cc_sicomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-CompartirIgual 4.0 Internacional (CC BY-SA 4.0)"){
        this.cc_compartir = "cc_sicondicion";
        this.cc_comercial = "cc_sicomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-SinDerivadas 4.0 Internacional (CC BY-ND 4.0)"){
        this.cc_compartir = "cc_nocomparte";
        this.cc_comercial = "cc_sicomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-NoComercial 4.0 Internacional (CC BY-NC 4.0)"){
        this.cc_compartir = "cc_sicomparte";
        this.cc_comercial = "cc_nocomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-NoComercial-CompartirIgual 4.0 Internacional (CC BY-NC-SA 4.0)"){
        this.cc_compartir = "cc_sicondicion";
        this.cc_comercial = "cc_nocomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-NoComercial-SinDerivadas 4.0 Internacional (CC BY-NC-ND 4.0)"){
        this.cc_compartir = "cc_nocomparte";
        this.cc_comercial = "cc_nocomercial";
      }
    }else{
      this.array_di.licencia={
        licencia: {
          concedo_licencia : false,
          creative_commons : "Atribución 4.0 Internacional (CC BY 4.0)",
          url : "https://creativecommons.org/licenses/by/4.0/legalcode.es"
        }
      }
      this.cc_compartir = "cc_sicomparte";
      this.cc_comercial = "cc_sicomercial";
    }

    this.cc(this.cc_compartir);
  }

  preselectDefaultLicense() {
    // Pre-selecciona la licencia por defecto
    let data = "cc_sicomparte"; 
    this.cc(data);
  }

  cc(data: string): void{
    /* this.onCcChange.emit({ data: data}); */
    if(data == "cc_sicomparte" && this.cc_comercial == "cc_sicomercial" || data == "cc_sicomercial" && this.cc_compartir == "cc_sicomparte"){
      this.titulo_cc = "Licencia Creative Commons Atribución 4.0 Internacional";
      var titulo_dc_right = "Atribución 4.0 Internacional (CC BY 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by/4.0/deed.es";
      this.img_cc = "../../../assets/img/ccby40.png";
      this.img_licencia = "../../../assets/img/seal.png";
      this.descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
      this.array_di.licencia.creative_commons = titulo_dc_right;
      this.array_di.licencia.url = this.url_cc;
      this.by = "El crédito debe ser otorgado a usted, el creador.";
      this.img_userby = "../../../assets/img/userby.png";
    }

    if(data == "cc_sicondicion" && this.cc_comercial == "cc_sicomercial" || data == "cc_sicomercial" && this.cc_compartir == "cc_sicondicion"){
      this.url_cc = "https://creativecommons.org/licenses/by-sa/4.0/deed.es";
      this.titulo_cc = "Licencia Creative Commons Atribución-CompartirIgual 4.0 Internacional";
      var titulo_dc_right = "Atribución-CompartirIgual 4.0 Internacional (CC BY-SA 4.0)";
      this.img_cc = "../../../assets/img/ccbysa40.png";
      this.img_licencia = "../../../assets/img/seal.png";
      this.descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
      this.array_di.licencia.creative_commons = titulo_dc_right;
      this.array_di.licencia.url = this.url_cc;
      this.img_sa = "../../../assets/img/sa.png";
      this.sa = "Las adaptaciones deben compartirse en los mismos términos.";
    }

    if(data == "cc_nocomparte" && this.cc_comercial == 'cc_sicomercial' || data == "cc_sicomercial" && this.cc_compartir == "cc_nocomparte"){
      this.url_cc = "https://creativecommons.org/licenses/by-nd/4.0/deed.es";
      this.titulo_cc = "Licencia Creative Commons Atribución-SinDerivadas 4.0 Internacional";
      var titulo_dc_right = "Atribución-SinDerivadas 4.0 Internacional (CC BY-ND 4.0)";
      this.img_cc = "../../../assets/img/ccbynd40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.array_di.licencia.creative_commons = titulo_dc_right;
      this.array_di.licencia.url = this.url_cc;
      this.img_nd = "../../../assets/img/nd.png";
      this.nd = "No se permiten derivados ni adaptaciones de su trabajo.";
    }

    if(data == "cc_nocomercial" && this.cc_compartir == "cc_sicomparte" || data == "cc_sicomparte" && this.cc_comercial == "cc_nocomercial"){
      this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial 4.0 Internacional";
      var titulo_dc_right = "Atribución-NoComercial 4.0 Internacional (CC BY-NC 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by-nc/4.0/deed.es";
      this.img_cc = "../../../assets/img/ccbync40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.array_di.licencia.creative_commons = titulo_dc_right;
      this.array_di.licencia.url = this.url_cc;
      this.img_nc = "../../../assets/img/nc.png";
      this.nc = "Sólo se permite el uso no comercial de su trabajo.";
    }
    if(data == "cc_nocomercial" && this.cc_compartir == "cc_sicondicion" || data == "cc_sicondicion" && this.cc_comercial == "cc_nocomercial"){
      this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial-CompartirIgual 4.0 Internacional";
      var titulo_dc_right = "Atribución-NoComercial-CompartirIgual 4.0 Internacional (CC BY-NC-SA 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by-nc-sa/4.0/deed.es";
      this.img_cc = "../../../assets/img/ccbyncsa40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.array_di.licencia.creative_commons = titulo_dc_right;
      this.array_di.licencia.url = this.url_cc;
      this.img_sa = "../../../assets/img/sa.png";
      this.sa = "Las adaptaciones deben compartirse en los mismos términos.";
      this.img_nc = "../../../assets/img/nc.png";
      this.nc = "Sólo se permite el uso no comercial de su trabajo.";
    }
    if(data == "cc_nocomercial" && this.cc_compartir == "cc_nocomparte" || data == "cc_nocomparte" && this.cc_comercial == "cc_nocomercial"){
      this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial-SinDerivadas 4.0 Internacional";
      var titulo_dc_right = "Atribución-NoComercial-SinDerivadas 4.0 Internacional (CC BY-NC-ND 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by-nc-nd/4.0/deed.es";
      this.img_cc = "../../../assets/img/ccncnd40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.array_di.licencia.creative_commons = titulo_dc_right;
      this.array_di.licencia.url = this.url_cc;
      this.img_nc = "../../../assets/img/nc.png";
      this.nc = "Sólo se permite el uso no comercial de su trabajo.";
      
    }
  }
}
