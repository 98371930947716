import { Component, OnInit } from '@angular/core';
/* import footer from './footer-02.json'; */
import footer from '../../../assets/json/footer-02.json';

@Component({
  selector: 'app-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.css']
})
export class BottomComponent implements OnInit {

  footer: any = footer['footer-02'];

  constructor() { }

  ngOnInit() {
  }

}
