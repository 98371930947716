import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import json from '../../../assets/json/search-advanced/search-advanced.json';
import { QueriesService } from '../../services/queries.service';
import { url } from 'inspector';
import { Console, timeStamp } from 'console';
import { event } from 'jquery';
import { stringify } from 'querystring';
import { Router } from '@angular/router';
import { DataGlobalService }  from '../../services/data-global.service';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css']
})
export class BusquedaComponent implements OnInit {

  public json: any = json;
  public data: any = [];
  public urlPhp;
  public urlImg;
  public valorSearch 
  public filtro: any;
  public registros;
  public totalPage;
  public page;
  public npage: any = 1;
  public cantidadReg;
  public paginacion = [];
  public nextPage;
  public prevPage;
  public urlFiltro;
  public tipodoc: any = [];
  public autores: any = [];
  public ods: any = [];
  public tematicas: any = [];
  public tematicasFiltro: any = [];
  public odsFiltro: any = [];
  public autoresFiltro: any = [];
  public tipoFiltro: any = [];
  public todoEnUno: any = [];
  public tituloFacetaFront: any = '';
  public materias: any = [];
  public idiomas: any = [];
  public facetas_desde;
  public facetas_hasta;
  public orden;
  public campus;
  public selectMenu;
  public disabled = false;
  public tipoDocEnArray: any = '';
  public loaderCarga;
  public sindatos;
  
  public arrayFilter = [
    {
      "name": "Buscar en",
      "options": "Todos"
    },
    {
      "name": "Tipo",
      "options": "Todos"
    },
    {
      "name": "Fecha",
      "options": ""
    },
    {
      "name": "Repositorio",
      "options": "Todos"
    }
  ]

  constructor(
    private queriesService: QueriesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataGlobal : DataGlobalService
  ) {
    this.urlPhp = this.dataGlobal.apiDiscovery;
    this.urlImg = this.dataGlobal.urlImg;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['select']) {
        var select = params['select'];
        var options = params['option'];
        var valor = params['valor'];
        var repo = params['repo'];
        var tipo = params['tipo'];
        for (let i = 0; this.arrayFilter.length > i; i++) {
          if (json['horizon_search_form']['filtros']['content'][i].name == select) {
            this.arrayFilter[i]["name"] = select
            this.arrayFilter[i]["options"] = options
            localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));
          }
          if (json['horizon_search_form']['filtros']['content'][i].name == "Repositorio") {
            this.arrayFilter[i]["name"] = "Repositorio"
            this.arrayFilter[i]["options"] = repo
            localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));
          }
        }
        if (!valor || valor == ' ') {
          localStorage.setItem('search_form', "");
        }
        else {
          localStorage.setItem('search_form', valor);
        }
        localStorage.setItem('page', '1');
        this.searchMemory(1);
      } else {
        this.searchMemory(2);
      }
    });  
    //Se invoca buscar para activar el router.navigate
    this.buscar('1', 'busqueda', 'asc', 0)
    //Se elimina el scroll hacia abajo 
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);
  }


  SubmenusMob(val) {

    if (this.selectMenu) {
      this.selectMenu = false;
    } else {
      this.selectMenu = val;
    }
  }

  buscarInput(event) {

    localStorage.setItem('search_form', event);
    this.buscar('1', 'busqueda', 'asc', 0)
  }

  searchMemory(number) {

        //Buscar en localStorage tematicasFiltro y todoEnUno 
        const tematicasFiltroURL = localStorage.getItem('tematicasFiltroURL') != null; // Si hay algo en localStorage
        const autoresFiltro = localStorage.getItem('autoresFiltro') != null; // Si hay algo en localStorage
        const odsFiltro = localStorage.getItem('odsFiltro') != null; // Si hay algo en localStorage 

        const todoEnUnoStorage = localStorage.getItem('todoenuno') != null; // Si hay algo en localStorage

       if (todoEnUnoStorage) {
         this.todoEnUno = JSON.parse(localStorage.getItem('todoenuno')).x;
         this.arrayFilter = JSON.parse(localStorage.getItem('arrayFilter'))

        if(tematicasFiltroURL) {
          this.tematicasFiltro = localStorage.getItem('tematicasFiltroURL').split(',');
          console.log("tematicasFiltroURL", this.tematicasFiltro, number)
        }
    
        if(autoresFiltro) {
          this.autoresFiltro = JSON.parse(localStorage.getItem('autoresFiltro')).x;
          //si autooresFiltro tiene una coma entre los los valores se la saco
        }

        if(odsFiltro) {
          this.odsFiltro = JSON.parse(localStorage.getItem('odsFiltro')).x;
        }

         this.buscar(1, '', '', 0);

       } else {
          if(number == 2) {
            localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));
            this.buscar(1, '', '', 0);
          }
       }
       
  }
  
  buscar(page, filtro, orden, locationFilter) {
    document.getElementById('buscador').scrollIntoView({ behavior: 'smooth' }); // Scroll hacia buscador
    this.data['listFacetas'] = false; 
    let str_filtros: any = '';

    //Loader Carga
    this.loaderCarga = true ;
    setTimeout('this.loaderCarga = true', 1000);
    //Fin Loader Carga
    
    this.sindatos = false;
    this.arrayFilter = JSON.parse(localStorage.getItem('arrayFilter'));
    
    // SI VIENE DE ficha
    if (localStorage.getItem('ficha') == 'true') {
      page = Number(localStorage.getItem('page'));
      this.arrayFilter = JSON.parse(localStorage.getItem('arrayFilter'));
      localStorage.removeItem('ficha');
      localStorage.removeItem('filtroActivo');
    }
    this.npage = page;
    localStorage.setItem('page', page);
    page = (page - 1) * 20;

    this.orden = orden;

    this.valorSearch = localStorage.getItem('search_form');

    // RECORRO ARRAY DE FILTROS
    if (this.valorSearch == '') this.filtro = '';
    //restaurar el valor de router a Busqueda/
    if(!this.valorSearch) this.router.navigate(['/busqueda']);
    
    //aqui se arma la URL de los filtros
    for (let i = 0; i < this.arrayFilter.length; i++) { /* Resto uno porque Repositorio no se ingresa al filtro */
      this.arrayFilter[i]['options'] = this.arrayFilter[i]['options'];
      let termm = this.arrayFilter[i]['options'];
      let term = termm.replace(".", "").replace(":", "").replace("-", " ");

      switch (this.arrayFilter[i]['name']) {
        case 'Buscar en':
          
          //Si se selecciona Titulo y valor de search esta lleno
          if (this.arrayFilter[i]['options'] == "Título" && this.valorSearch) {
            this.filtro = 'fq=dc.title:"' + filtro + '"%26';
          }
          
          //Si se selecciona autor y valor de search esta lleno
          if (this.arrayFilter[i]['options'] == "Autor" && this.valorSearch && this.autoresFiltro.length > 0) {
            localStorage.setItem('autoresFiltro', JSON.stringify({x:this.autoresFiltro}));
            if (this.tematicasFiltro.length === 0) this.filtro = ''
            for (const autor of this.autoresFiltro) {
              this.filtro += 'fq=bi_2_dis_value_filter:"' + autor + '"%26';
            }
          } else if(this.arrayFilter[i]['options'] == "Autor" && this.valorSearch && locationFilter == 1){}
          
          //Si se selecciona tematica y valor de search esta lleno
          if (this.arrayFilter[i]['options'] == "Temática" && this.valorSearch && this.tematicasFiltro.length > 0) {
            localStorage.setItem('tematicasFiltroURL', this.tematicasFiltro);
            if (this.autoresFiltro.length === 0) this.filtro = ''
            for (const tematica of this.tematicasFiltro) {
              this.filtro += 'fq=bi_4_dis_value_filter:"' + tematica + '"%26';
            }
          } else if(this.arrayFilter[i]['options'] == "Temática" && this.valorSearch && locationFilter == 1){}
          
          //si valorSearch esta lleno y se selecciona una tematica y luego un autor
          if (this.valorSearch && this.autoresFiltro.length > 0 && this.tematicasFiltro.length > 0 && locationFilter != 1) {
            localStorage.setItem('tematicasFiltroURL', this.tematicasFiltro);
            localStorage.setItem('autoresFiltro', JSON.stringify({x:this.autoresFiltro}));
            this.filtro = ''
            for (const autor of this.autoresFiltro) {
              this.filtro += 'fq=bi_2_dis_value_filter:"' + autor + '"%26';
            }
            for (const tematica of this.tematicasFiltro) {
              this.filtro += 'fq=bi_4_dis_value_filter:"' + tematica + '"%26';
            }
          }

          // //Si valorSearch esta lleno y se selecciona una tematica y luego un ods
          if (this.valorSearch && this.tematicasFiltro.length > 0 && this.odsFiltro > 0 && locationFilter != 1) {
            localStorage.setItem('tematicasFiltroURL', this.tematicasFiltro);
            localStorage.setItem('odsFiltro', this.odsFiltro);
            this.filtro = ''
            
            for (const tematica of this.tematicasFiltro) {
              this.filtro += 'fq=bi_4_dis_value_filter:"' + tematica + '"%26';
            }
            for (const ods of this.odsFiltro) {
              this.filtro += 'fq=bi_7_dis_value_filter:"' + ods + '"%26';
            }
          } else if(this.valorSearch && locationFilter == 1){
            this.filtro = ''
            for (const tematica of this.tematicasFiltro) {
              this.filtro += 'fq=bi_4_dis_value_filter:"' + tematica + '"%26';
            }
            for (const ods of this.odsFiltro) {
              this.filtro += 'fq=bi_7_dis_value_filter:"' + ods + '"%26';
            }
          }
          
          //si valor de search esta lleno y autor vacio y tematica vacio
          if (this.valorSearch && this.autoresFiltro.length === 0 && this.tematicasFiltro.length === 0) {
            this.filtro = ''
          }
          
          //si valor de search esta lleno toma esta condicion desde click en tematica + repositorio villarrica en home al hacer click caiga en el valor seleccionado
          if (this.valorSearch && this.autoresFiltro.length === 0 && this.tematicasFiltro.length === 0 && locationFilter != 1) {
           
            if(this.tematicasFiltro)
            this.tematicasFiltro.push(this.valorSearch)
            this.filtro = ''
            for (const tematica of this.tematicasFiltro) {
              this.filtro += 'fq=bi_4_dis_value_filter:"' + tematica + '"%26';
            }
            //vacias valor tematicasFiltro cuando se borre el search
            if(this.valorSearch != "") {
              this.tematicasFiltro = []
            }
          }
          
          //si valor de search esta vacio
          if (!this.valorSearch && locationFilter != 1) {
            this.filtro = ''
            if (this.autoresFiltro.length < 1 && this.tematicasFiltro.length < 1 && this.odsFiltro.length <1) break
            for (const tematica of this.tematicasFiltro) {
              this.filtro += 'fq=bi_4_dis_value_filter:"' + tematica + '"%26';
              console.log("tematicasFiltro cuando search vacio", this.tematicasFiltro, locationFilter)
              //a ver mandemos esto a localStorage si tematicasFiltro tiene valor
             if(this.tematicasFiltro.length > 0 || this.tematicasFiltro.length === 0 ) {
              localStorage.setItem('tematicasFiltroURL', tematica);
             }  
            }
            for (const autor of this.autoresFiltro) {
              this.filtro += 'fq=bi_2_dis_value_filter:"' + autor + '"%26';
            }
            for (const ods of this.odsFiltro) {
              this.filtro += 'fq=bi_7_dis_value_filter:"' + ods + '"%26';
            }
            console.log("odsFiltro cuando search vacio", this.odsFiltro, locationFilter)
          } else if(!this.valorSearch && locationFilter == 1) {
            this.filtro = ''
            for (const tematica of this.tematicasFiltro) {
              this.filtro += 'fq=bi_4_dis_value_filter:"' + tematica + '"%26';
            }
            for (const ods of this.odsFiltro) {
              this.filtro += 'fq=bi_7_dis_value_filter:"' + ods + '"%26';
            }
          } 

          //Si valor de search esta lleno y tematica vacio y autor vacio y se selecciona ods
          if (this.valorSearch && this.odsFiltro.length > 0 && locationFilter != 1) {
            localStorage.setItem('odsFiltro', this.odsFiltro);
            if (this.autoresFiltro.length === 0 && this.tematicasFiltro.length === 0) this.filtro = ''
  
            if (this.autoresFiltro.length < 1 && this.tematicasFiltro.length < 1 && this.odsFiltro.length < 1) break
            
            for (const ods of this.odsFiltro) {
              this.filtro += 'fq=bi_7_dis_value_filter:"' + ods + '"%26';
              console.log("odsFiltro cuando search lleno", this.filtro)
            }
            
          } else if(this.arrayFilter[i]['options'] == "ods" && this.valorSearch && locationFilter == 1){
            if (this.autoresFiltro.length < 1 && this.tematicasFiltro.length < 1 && this.odsFiltro.length < 1) this.filtro = ''
            for (const ods of this.odsFiltro) {
              this.filtro += 'fq=bi_7_dis_value_filter:"' + ods + '"%26';
            }
          }
          
        break;

        case 'Tipo':
          if(termm){
            this.filtro += 'fq=dc.type:"' + termm + '"%26';
            if(this.valorSearch){
              this.filtro = 'fq=dc.type:"' + termm + '"%26';
            }
          }
        break;

        case 'Fecha':
          if (termm) { this.filtro = 'fq=dateIssued:' + termm + '%26' }
          break;
       
        case 'Repositorio':
          this.campus = term
          console.log("campus", this.campus)
        break;
      }

      if (this.arrayFilter[i]['name'] != "Repositorio" && this.arrayFilter[i]['options'] != "Todos" && this.arrayFilter[i]['options'] != "") {
        str_filtros += this.filtro;
        console.log("str_filtros", str_filtros)

      } 
    }

    this.urlFiltro = this.urlPhp + 'filtro=' + str_filtros + '&valor=' + this.valorSearch + '&start=' + page + '&orden=' + this.orden + '&campus=' + this.campus;
    console.log("url filtro", this.urlFiltro)
    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.queriesService.queryGet(this.urlFiltro)
      .then((data) => {

        this.data['search'] = Object.keys(data).map(i => data[i]);
        this.loaderCarga = false;
        // CONSTRUYO MENU FACETAS
        var x = 0;
        var arr = [];
        var tipodoc: any = [];
        var autores_arreglo: any = [];
        var tematicas_arreglo: any = [];
        var ods_arreglo: any = [];
        var idioma_arreglo: any = [];
        var materias_arreglo: any = [];

        // Facetas tematicas 28-05-2024
        if(this.data['search'][2]['facet_fields']['bi_5_dis_value_filter']){
          this.data['tematicas'] = this.data['search'][2]['facet_fields']['bi_5_dis_value_filter'];

          for (let i = 0; i < this.data['tematicas'].length / 2; i++) {
            x = i * 2;
            arr = [];
            arr.push(this.data['tematicas'][x]);
            x = (i * 2) + 1;
            arr.push(this.data['tematicas'][x]);
            tematicas_arreglo.push(arr);
          }
          this.tematicas = tematicas_arreglo;
        }

        //Facetas ODS
        this.data['ods'] = this.data['search'][2]['facet_fields']['bi_7_dis_value_filter'];

        var index_ods = 0;
        var arr_ods = [];
  
        for (let i = 0; i < this.data['ods'].length / 2; i++) {
          index_ods = i * 2;
          arr_ods = [];
          arr_ods.push(this.data['ods'][index_ods]);
          index_ods = (i * 2) + 1;
          arr_ods.push(this.data['ods'][index_ods]);
          ods_arreglo.push(arr_ods);
        }
        this.ods = ods_arreglo;

        // Facetas autores
        this.data['autores'] = this.data['search'][2]['facet_fields']['bi_2_dis_value_filter'];
        for (let i = 0; i < this.data['autores'].length / 2; i++) {
          x = i * 2;
          arr = [];
          arr.push(this.data['autores'][x]);
          x = (i * 2) + 1;
          arr.push(this.data['autores'][x]);
          autores_arreglo.push(arr);
        }
        this.autores = autores_arreglo;

        // Facetas tipo documento
        this.data['tipodoc'] = this.data['search'][2]['facet_fields']['type_filter'];
        for (let i = 0; i < this.data['tipodoc'].length / 2; i++) {
          x = i * 2;
          arr = [];
          arr.push(this.data['tipodoc'][x]);
          x = (i * 2) + 1;
          arr.push(this.data['tipodoc'][x]);
          tipodoc.push(arr);
        }
        this.tipodoc = tipodoc;

        // Facetas idioma
        this.data['idiomas'] = this.data['search'][2]['facet_fields']['language_filter'];
        for (let i = 0; i < this.data['idiomas'].length / 2; i++) {
          x = i * 2;
          arr = [];
          arr.push(this.data['idiomas'][x]);
          x = (i * 2) + 1;
          arr.push(this.data['idiomas'][x]);
          idioma_arreglo.push(arr);
        }
        this.idiomas = idioma_arreglo;

        // fin filtros facetas

        // PAGINACIÓN
        this.totalPage = this.data['search'][1]['numFound'];
        this.cantidadReg = 20;
        this.page = Math.ceil(this.totalPage / this.cantidadReg);


        this.paginacion = []; // Dejar vacío para volver a crear loop con cada consulta
        for (let i = 1; i <= this.page; i++) {
          if (i <= 5) {
            if (this.npage > 5) {
              this.paginacion.push(i + (this.npage - 5));
            } else {
              this.paginacion.push(i);
            }
          }
        }
        if (this.npage >= 2) {
          this.prevPage = this.npage - 1;
        } else {
          this.prevPage = 1;
        }
        if (this.npage < this.page) {
          this.nextPage = this.npage + 1;
        } else {
          this.nextPage = this.page;
        }
        // Fin paginación

        // MUESTRA/OCULTA SECCIÓN REGISTROS
        if (this.data['search'][1]['numFound'] == undefined || this.data['search'][1]['numFound'] == 0) {
          this.registros = false; 
          this.sindatos = true;
        } else {
          this.registros = true;
          this.sindatos = false;
        }
      });
  }

  mixFilter(select, option, search, locationFilter) {
    /* console.log("select", select, "option", option, "search", search, "locationFilter", locationFilter)
    console.log("option", option, "locationFilter", locationFilter) */
    if (select === 'Buscar en') {
      this.arrayFilter[0].options = option //aqui busca donde esta si en tematica, todos o autor
      localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));//lo guarda en localStorage

      // Si es temática la opcion seleccionada
      if (option === "Temática") {
        const facetaInclude = this.tematicasFiltro.includes(search)
        const todoEnUnoIncludeTematica = this.todoEnUno.includes(search)
        if (facetaInclude || todoEnUnoIncludeTematica) return
        if(locationFilter != 1){
          this.tematicasFiltro.push(search)
          this.todoEnUno.push(search) 
        }
      }
      
      else if (option === "ods") {
        const facetaIncludeOds = this.odsFiltro.includes(search)
        const todoEnUnoIncludeOds = this.todoEnUno.includes(search)
        if (facetaIncludeOds || todoEnUnoIncludeOds) return
        if(locationFilter != 1){
          this.odsFiltro.push(search)
          this.todoEnUno.push(search)
          console.log("odsFiltro", this.odsFiltro, this.todoEnUno)
        } else if(locationFilter == 1) {
          console.log("odsFiltro igual 1", this.odsFiltro, this.todoEnUno, search)
          this.odsFiltro.push(search)
          this.todoEnUno.push(search)
        }
      } 
      
      else if (option === "Autor") {

        const facetaIncludeAutor = this.autoresFiltro.includes(search)
        const todoEnUnoIncludeAutor = this.todoEnUno.includes(search)
        if (facetaIncludeAutor || todoEnUnoIncludeAutor) return
        if(locationFilter != 1){
          this.autoresFiltro.push(search)
          localStorage.setItem('autoresFiltro', JSON.stringify({x:this.autoresFiltro}));
          this.todoEnUno.push(search)
        }
      } 
      //cuando se seleccionan otros tipos como tipo de documento, fecha, y repositorio
      } else if (select === 'Tipo') {
        this.arrayFilter[1].options = option

        this.todoEnUno = this.todoEnUno.concat(this.arrayFilter[1].options)
        localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));//lo guarda en localStorage

      } else if (select === 'Fecha') {
        this.arrayFilter[2].options = option //aqui busca donde esta si en tematica, todos o autor
        localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));//lo guarda en localStorage
        
      } else if (select === 'Repositorio') {
        this.arrayFilter[3].options = option //aqui busca donde esta si en tematica, todos o autor
        localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));//lo guarda en localStorage
      }
      //Si array todoEnUno tiene data se manda a localStorage
      if(this.todoEnUno.length > 0) {
        localStorage.setItem('todoenuno', JSON.stringify({x:this.todoEnUno}))
        console.log("todoEnUno", this.todoEnUno)
      } 

      this.buscar(1, search, "asc", locationFilter) 
  }

  elimFac(valor, lugar) {
    this.tematicasFiltro.pop(valor)
    if (this.tematicasFiltro.length == 0) {
      this.arrayFilter[0].options = lugar
      localStorage.removeItem('tematicasFiltroURL')
      localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter))
    }
    this.buscar(1, "", "asc", 0)
  }
  
  elimAut(valor, lugar) {
    this.autoresFiltro.pop(valor)
    
    if (this.autoresFiltro.length == 0) {
      this.arrayFilter[0].options = lugar
      localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter))
      localStorage.removeItem('autoresFiltro')
    }
    this.buscar(1, "", "asc", 0)
  }

  elimOds(valor, lugar) {
    this.odsFiltro.pop(valor)
    if (this.odsFiltro.length == 0) {
      this.arrayFilter[0].options = lugar
      localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter))
      localStorage.removeItem('odsFiltro')
    }
    this.buscar(1, "", "asc", 0)
  }

  elimTipoDeFiltro(valor, lugar) {
    if(this.arrayFilter[1].options.includes(valor)) {
      this.arrayFilter[1].options = lugar
    }
    localStorage.setItem('arrayFilter', JSON.stringify(this.arrayFilter));
    this.buscar(1, "", "asc", 0)
  }

  eliminarItesDeTodoEnUno(valor,indice) {
    var validateIndex = this.todoEnUno.length-1 === indice
    const elimTodoValor = this.todoEnUno.findIndex((item) => item === valor)
    var existe = false
    if(validateIndex) {
      this.todoEnUno.splice(elimTodoValor, 1)
      const lugar =  this.searchUltimateValue(this.todoEnUno[this.todoEnUno.length-1])
      if(this.todoEnUno.length === 0) {
        localStorage.removeItem('todoenuno')
      }
      this.tematicasFiltro.map((item) => {
        if(item === valor){
          existe = true
          this.elimFac(valor, lugar)
        }
      }) 
      this.autoresFiltro.map((item) => {
        if(item === valor){
          existe = true
          this.elimAut(valor, lugar)
        }
      })
      this.odsFiltro.map((item) => {
        if(item === valor){
          existe = true
          this.elimOds(valor, lugar)
        }
      })
      if(this.arrayFilter[1].options === valor){
        if(this.todoEnUno.length >= 1 && lugar === 'Todos'){
          this.elimTipoDeFiltro(valor, this.todoEnUno[this.todoEnUno.length-1])
        } else {
          this.elimTipoDeFiltro(valor, 'Todos')
        }
      }
    }
   
  }
  
  searchUltimateValue(valor) {
    let result = 'Todos'
    this.tematicasFiltro.map((item) => {
      if(item === valor){
        result = 'Temática'
      }
    }) 
    this.autoresFiltro.map((item) => {
      if(item === valor){
        result = 'Autor'
      }
    })
    //ods
    this.odsFiltro.map((item) => {
      if(item === valor){
        result = 'ods'
      }
    })
    //evaluar el array de tipo de documento para saber si es el ultimo valor
    console.log("valor", valor, "result", result)
    return result
  }

  /* Desde menu facetas despliega todas las facetas */
  searchFacetas(select, param, num) {
    this.data['listFacetas'] = true;
    this.data['search'] = false;

    if (param == 'autores') {
      this.data['facetas'] = this.autores;
      this.data['select'] = 'Buscar en';
      this.data['tipo'] = 'Autor';
    }
    if (param == 'Temática') {
      this.data['facetas'] = this.tematicas;
      this.data['select'] = 'Buscar en';
      this.data['tipo'] = "Temática";
    }
    if (param == 'ods') {
      this.data['facetas'] = this.ods;
      this.data['select'] = 'Buscar en';
      this.data['tipo'] = "ods";
    }
    /* if(param=='idioma'){
      this.data['facetas'] = this.idiomas;
      this.data['tipo'] = param;
    } */
    if (select == 'Tipo') {
      this.data['facetas'] = this.tipodoc;
      this.data['select'] = select;
      this.data['tipo'] = param;
    }


    // PAGINACIÓN
    this.totalPage = this.data['facetas'].length;
    this.cantidadReg = 50;
    this.npage = num;
    num = (num - 1) * 20;
    this.page = Math.ceil(this.totalPage / this.cantidadReg);
    this.paginacion = []; // Dejar vacío para volver a crear loop con cada consulta
    this.facetas_desde = (this.npage - 1) * this.cantidadReg;
    this.facetas_hasta = (this.npage) * this.cantidadReg;

    for (let i = 1; i <= this.page; i++) {
      if (i <= 5) {
        if (this.npage > 5) {
          this.paginacion.push(i + (this.npage - 5));
        } else {
          this.paginacion.push(i);
        }
      }
    }
    if (this.npage >= 2) {
      this.prevPage = this.npage - 1;
    } else {
      this.prevPage = 1;
    }
    if (this.npage < this.page) {
      this.nextPage = this.npage + 1;
    } else {
      this.nextPage = this.page;
    }
    // Fin paginación
  }
}




