import {
  Component,
  OnInit,
  DoCheck,
  Input
} from '@angular/core';
import {
  Observable
} from 'rxjs';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl
} from '@angular/platform-browser';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/mergeMap';
// Animacion slider
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  reduce
} from 'rxjs/operators';

import json from '../../../assets/json/home.json';
import Noticias from '../../../assets/json/noticias/noticias.json';
import AreasTematicas from '../../../assets/json/areas-tematicas.json';
import CajonBusqueda from '../../../assets/json/cajon-busqueda.json';
import EstudioBibliometricos from '../../../assets/json/estudios-bibliometricos.json';
import {
  environment
} from "../../../environments/environment";

import {
  QueriesService
} from '../../services/queries.service';
import {
  SubscribeService
} from '../../services/subscribe.service';

import { DataGlobalService }                                    from '../../services/data-global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({
        transform: 'translateX(0)'
      })),
      state('t2', style({
        transform: 'translateX(-25%)'
      })),
      state('t3', style({
        transform: 'translateX(-50%)'
      })),
      state('t4', style({
        transform: 'translateX(-75%)'
      })),

      transition('* => *', animate(300))
    ])
  ]

})
export class HomeComponent implements OnInit, DoCheck {

  @Input() tipoSelect: any;
  @Input() tipoTipo: any;

  json: any = json;
  posts: any = AreasTematicas['areas-tematicas'];
  CajonBusqueda: any = CajonBusqueda;
  data: any = [];
  loggedIn: any = false;
  usuario;
  correo;
  unidad;
  userData: any = [];
  loginFailed: any = "";
  angForm: FormGroup;
  editForm: FormGroup;
  categorias: any = [];
  tipos_villarrica: any = [];
  tipos_ods: any = [];
  status;
  public buscalibro = {
    name: ''
  };
  subscribeData: any = < any > {};
  urlOrcid;
  private goOrcid: boolean = false;

  // Banner slide
  private bnr = [{
      slide: 't1'
    },
    {
      slide: 't2'
    },
    {
      slide: 't3'
    },
    {
      slide: 't4'
    }
  ];
  slide: any = "t1"; //this.randomSlide();
  public activePane: string = this.slide;
  bnrsilder;

  constructor(
    private subscribeService: SubscribeService,
    private router: Router,
    private queriesService: QueriesService, 
    private _sanitizer: DomSanitizer, 
    private formBuilder: FormBuilder,
    private dataGlobal : DataGlobalService
    )
    { 
    }

  ngOnInit() {

    // refresca página
    this.queriesService.getRefresh();


    // Banner Imagenes
    this.playSlider()

    // TEXTOS PÁGINA
    this.json = json;

    // NOTICIAS
    this.data['news'] = Noticias;

    // AREAS TEMÁTICAS VILLARRICA
    this.queriesService.queryGet(this.dataGlobal.apiCatVillarrica).then((data) => {

      this.data['topics-villarrica'] = data['facet_counts']['facet_fields']['bi_5_dis_value_filter'];
      /* console.log(this.data['topics-villarrica']) */
      var x = 0;
      var arr = [];

      for (let i = 0; i < this.data['topics-villarrica'].length / 2; i++) {
        x = i * 2;
        arr = [];
        arr.push(this.data['topics-villarrica'][x]);
        x = (i * 2) + 1;
        arr.push(this.data['topics-villarrica'][x]);
        this.tipos_villarrica.push(arr);
      }
    });

    //ODS
    this.queriesService.queryGet(this.dataGlobal.apiCatOds).then((data) => {
      this.data['topics-ods'] = data['facet_counts']['facet_fields']['bi_7_dis_value_filter'];

      var index_ods = 0;
      var arr_ods = [];

      for (let i = 0; i < this.data['topics-ods'].length / 2; i++) {
        index_ods = i * 2;
        arr_ods = [];
        arr_ods.push(this.data['topics-ods'][index_ods]);
        index_ods = (i * 2) + 1;
        arr_ods.push(this.data['topics-ods'][index_ods]);
        this.tipos_ods.push(arr_ods);
      }
    });

    // AREAS TEMÁTICAS
    this.queriesService.queryGet(this.dataGlobal.apiCategorias).then((data) => {
      this.data['topics'] = data['facet_counts']['facet_fields']['bi_5_dis_value_filter'];
      var x = 0;
      var arr = [];
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } 

      for (let i = 0; i < this.data['topics'].length / 2; i++) {
        x = i * 2;
        arr = [];
        arr.push(this.data['topics'][x]);
        x = (i * 2) + 1;
        arr.push(this.data['topics'][x]);
        /* Agrego al array nombre sin caractares especiales ni espacios para usar en imagenes */
        x = (i * 2);
        arr.push(removeAccents(this.data['topics'][x].replace(/ /g,'')));

        this.categorias.push(arr);
      }
    });
    
    // SUSCRIBETE A NUESTRO NEWSLETTER
    this.data['SuscripcionNews'] = json['horizon_novedades']['section_suscription'];

    // NUESTROS NUMEROS
    this.queriesService.queryGet(this.dataGlobal.apiCantidadTesis).then((data) => {
      this.data['statistics-tesis'] = data['response']['numFound'];
    });

    this.queriesService.queryGet(this.dataGlobal.apiCantidadArticulos).then((data) => {
      const dataArticle = data['response']['numFound']
      this.data['statistics-articulos'] = dataArticle.toLocaleString('en').replace(/,/g, '.')
    });

    this.queriesService.queryGet(this.dataGlobal.apiCantidadLibros).then((data) => {
      this.data['statistics-libros'] = data['response']['numFound'];
    });

    // ESTUDIOS BIBLIOMETRICOS
    this.data['studies'] = EstudioBibliometricos['estudios-bibliometricos'];

  }
  ngDoCheck() {
    // COMPUEBO LOGIN
    if (localStorage.getItem('correo')) {
      this.loggedIn = true;
      this.usuario = localStorage.getItem('usuario');
      this.correo = localStorage.getItem('correo');
      this.unidad = localStorage.getItem('unidad');
    } else this.loggedIn = false
  }
  scrollToElement($element): void {
    document.getElementById('suscripcion').scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  // Banner Slider
  randomSlide() {
    const r = Math.floor(Math.random() * (this.bnr.length)) + 0;
    return this.bnr[r].slide;
  }
  finSlider() {
    clearInterval(this.bnrsilder);
  }
  playSlider() {
    this.bnrsilder = setInterval(() => {
      //this.currentImage = this.updateRandomImage();
      this.slide = this.randomSlide();
      this.activePane = this.activePane === this.slide ? this.slide : this.slide;
    }, 4000);
  }
  // Fin Banner Slider
  fixedCaja() {
    //$('#containerBusca').css('padding-top','200px');

    $(window).scroll(function () {
      if ($(window).scrollTop() == 0) {
        $('#cajaSearch').addClass('fixed');
        $('#containerBusca').addClass('margin-top-300');
      } else {
        $('#cajaSearch').removeClass('fixed');
        $('#containerBusca').removeClass('margin-top-300');
        //$('#containerBusca').css('margin-top','0px');
      }
    });

  }
  newsletter() {
    this.queriesService.queryGet(this.dataGlobal.apiSusNewsletter + this.subscribeData['email'])
      .then((data) => {
        //console.log(data);
        //console.log(this.subscribeData['email']);
        if (data['status'] == 200) {
          this.status = "success";
          //console.log(data['mensaje']);
        }
        if (data['status'] == 214) {
          this.status = "existe";
          //console.log(data['mensaje']);
        }
        if (data['status'] == 400) {
          this.status = "error";
          //console.log(data['mensaje']);
        }
      });
  }
  volverSuscribir() {
    this.status = false;
  }
  subscribe(form) {

    this.subscribeService.subscribeToList(this.subscribeData)
      .subscribe(res => {
        this.status = "success";
      }, err => {
        console.log(err);
      })
  }
  /* llamarLogin() {
    window.location.href = `${environment.cas_login_url}?service=${encodeURIComponent(window.location.href)}`;
  }
  loginCas(ruta: string) {
    login(ruta)
  }
  logout() {
    localStorage.removeItem('usuario');
    localStorage.removeItem('correo');
    localStorage.removeItem('unidad');
    window.location.href = 'assets/php/cas-log/logout-cas.php';
  } */
  getSearch(form) {
    localStorage.setItem('search_form', this.buscalibro.name);
    localStorage.setItem('json_filtros', "[]");
    localStorage.setItem('filtroActivo', 'false');
    localStorage.setItem('title', 'Búsqueda simple');
    localStorage.setItem('page', '1');
    this.router.navigate(['/busqueda']);
  }
  BusquedaAvanzada() {
    var array_Filtros: any[] = [{
      search_by: '',
      contains: '',
      term: ''
    }];
    localStorage.setItem('searchAdvanced', 'true');
    localStorage.setItem('search_form', '');
    localStorage.setItem('json_filtros', JSON.stringify(array_Filtros));
    localStorage.setItem('page', '1');

    this.router.navigate(['/busqueda']);
  }
  getStatistics(num) {
    localStorage.setItem('search_form', '');
    var arrayFilter: any[] = [{
        "name": "Buscar en",
        "options": "Todos"
      },
      {
        "name": "Tipo",
        "options": num
      },
      {
        "name": "Fecha",
        "options": "Todos"
      },
      {
        "name": "Repositorio",
        "options": "Todos"
      }
    ];
    localStorage.setItem('arrayFilter', JSON.stringify(arrayFilter));
    this.router.navigate(['/busqueda/Tipo/'+num+'/'+num+'/Todos/']);
  }
  getBackgroundImage(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(29, 29, 29, 0), rgba(16, 16, 23, 0.5)), url(${image})`);
  }
  getOrcidLoguear(ruta: string) {
    //si no esta logeado abre el cas en otra ventana e inicia sesion
    if (!this.loggedIn && !localStorage.getItem('correo')) {
      //llamar al cas y agregarle la ruta
       /* this.loginCas(ruta)  */
       this.dataGlobal.login(ruta)
    } else if(this.loggedIn && localStorage.getItem('correo')){
      window.open(ruta, '_blank');
    }
  }
  vincularOrcid(ruta: string) {
     //si no esta logeado abre el cas en otra ventana e inicia sesion
     if (!this.loggedIn && !localStorage.getItem('correo')) {
      /* this.loginCas(ruta); */
      this.dataGlobal.login(ruta)
    } else if(this.loggedIn && localStorage.getItem('correo')){
      window.open(ruta, '_blank');
    }
  }
}
