import { Component, OnInit, DoCheck }      from '@angular/core';
import { Meta }           from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, DoCheck {
  public title = 'front-repositorio-uc';
  public queryUrl;
  public status = 0;

  constructor(
    private metaTagService: Meta
  ) { 
  }


  ngOnInit() {

    this.metaTagService.addTags([
      { name: 'description', content: 'En el Repositorio UC encontrarás todo el catálogo digital de tesis, investigaciones y publicaciones académicas de la Universidad Católica de Chile.' },
      //{ name: 'keywords', content: 'Repositorio, uc, tesis, publicaciones académicas' },
      //{ name: 'author', content: 'Pontificia Universidad Católica de Chile - Biliotecas UC' },
      //{ name: 'date', content: '2020-05-31', scheme: 'YYYY-MM-DD' },
      //{ name: 'robots', content: 'index, follow' },
      //{ name: 'viewport', content: 'width=device-width; initial-scale=1.0; maximum-scale=1.0;' }
      { name: 'DC.contributor',       content: "" },
      { name: 'DC.creator',           content: "" },
      { name: 'DC.contributor1',       content: "" },
      { name: 'DCTERMS.dateAccepted', content: "" , scheme:'DCTERMS.W3CDTF'},
      { name: 'DCTERMS.available',    content: "" , scheme:'DCTERMS.W3CDTF'},
      { name: 'DCTERMS.issued',       content: "" , scheme:'DCTERMS.W3CDTF'},
      { name: 'DC.identifier',        content: "" , scheme:"DCTERMS.URI"},
      { name: 'DC.description',       content: "" },
      { name: 'DCTERMS.abstract',     content: "" },
      { name: 'DCTERMS.extent',       content: "" },
      { name: 'DC.language',          content: "" , scheme:"DCTERMS.RFC1766" },
      { name: 'DC.title',             content: "" },
      { name: 'DC.type',              content: "" },
      { name: 'DC.subject0',           content: "" },
      { name: 'DC.subject1',           content: "" },
      { name: 'DC.subject2',           content: "" },

      { name: 'citation_keywords',    content: "" },
      { name: 'citation_title',       content: "" },
      { name: 'citation_language',    content: "" },
      { name: 'citation_author',      content: "" },
      { name: 'citation_pdf_url',     content: "" },
      { name: 'citation_date',        content: "" },
      { name: 'citation_abstract_html_url', content: "" }
      
    ]);
    
  }

 ngDoCheck(){
   
 }

 
}
