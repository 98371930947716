import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name:"slash_derecha"
})
export class SlashDerechaPipe implements PipeTransform{

  transform(value:string):string {

    let splits = value.split('/');
    if(splits.length > 1) {
      return splits.pop();
    } else {
      return '';
    }
  }

}