import { Component, OnInit, Input } from '@angular/core';
import { forEach } from 'angular';

@Component({
  selector: 'app-card-publications',
  templateUrl: './card-publications.component.html',
  styleUrls: ['./card-publications.component.css']
})
export class CardPublicationsComponent implements OnInit {
  foto = '';
  @Input() data:        any;
  @Input() heightSame:  any;
  @Input() urlImg:  any;
  
  constructor() { }

  ngOnInit() {console.log(this.data); console.log(this.urlImg)}

  replaceUrl(i){ 
    const indice = i
    const dcIdentifier = this.data.docs[indice]
    const identifier = dcIdentifier['dc.identifier'][0]
    //console.log("identifier", identifier)
    //const replaceUrl = identifier.replace('https://doi.org/', 'https://handle.stage.datacite.org/');
    window.open(identifier, '_blank');
    /* console.log("urlDoi", identifier) */
  }

}
