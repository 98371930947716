import { Component, OnInit }  from '@angular/core';
import json                   from './footer.json';
import footer                 from '../../../assets/json/footer-01.json';
import MenuRepos              from '../../../assets/json/menu-repositorio.json';
import { DataGlobalService }  from '../../services/data-global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  //json:       any = json;
  footer:     any = footer['footer-01'];
  menuRepos:  any = MenuRepos['menu-repositorio-uc'];
  menuCorp:   any = MenuRepos['menu-corp'];
  msjf;
  estilof;
  iconof;
  num_version: any;

  constructor(
    private dataGlobalService: DataGlobalService
  ) { 
    this.dataGlobalService.versionesPortal();
  }

  ngOnInit() {
    $("#msjFooter").hide();
    this.num_version = this.dataGlobalService.versionPortal[0].version;
  }

  alertasFooter(estilo, icono, texto){
    
    $("#msjFooter").fadeIn(1000);
    setInterval(() => {
      $("#msjFooter").fadeOut(1500);
    }, 2000);
    this.msjf = texto;
    this.estilof = estilo;
    this.iconof = icono;
  }

}
