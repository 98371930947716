import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
/* import { global }     from '../services/global'; */
import { map }        from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataGlobalService } from '../services/data-global.service';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {
  /* URL = global.php+"/autoarchivo/"; */
  URL = this.dataGlobal.apiAutoarchivo;
  /* urlfile = global.php + "/autoarchivo/subirArchivo.php" */
  urlfile = this.dataGlobal.apiUploadPublicaciones;
  

  constructor( 
    private http: HttpClient,
    private dataGlobal : DataGlobalService ) { 
  }

  uploadFile(archivo) {
    return this.http.post(`${this.URL}subirArchivo.php`, JSON.stringify(archivo));
  }

  public subirfile(archivo){
    return new Promise( (resolve, reject) => {
      this.http.post(this.urlfile, JSON.stringify(archivo)).pipe(
        map( (res: Response) => res )
      ).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
          reject();
        }
      )
    });
  }

  register(archivo): Observable<any>{
    let params = JSON.stringify(archivo);
    console.log(archivo);
    //let params = 'json='+json;

    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http.post(this.urlfile, params, {headers: headers});
}

}
