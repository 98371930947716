import { Component, OnInit, Input } from '@angular/core';
import { DataGlobalService } from '../../../services/data-global.service'

@Component({
  selector: 'app-alert-declaracion',
  templateUrl: './alert-declaracion.component.html',
  styleUrls: ['./alert-declaracion.component.css']
})
export class AlertDeclaracionComponent implements OnInit {
  @Input() formulario:  any;
  constructor(
    public dataGlobalService: DataGlobalService
  ) { }

  ngOnInit() {
  }

  pestaniaDeclaracion(event){
    if(!event.target.checked){
      this.dataGlobalService.declaracion = false;
    }
    if(event.target.checked){
      this.dataGlobalService.declaracion = true;
    }
    this.dataGlobalService.modal = false
  }

}
