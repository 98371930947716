import { Component, OnInit } from '@angular/core';
import menu_uc from '../../../assets/json/menu-uc.json';

//import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
//import { FormGroup,  FormBuilder,  Validators }   from '@angular/forms';
//import { QueriesService } from '../../services/queries.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  MenuUc: any = menu_uc['menu-uc'];
  
  //data:     any = [];

  constructor(
    //private queriesService: QueriesService, private _sanitizer: DomSanitizer, private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

  }

}
