import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-card-ods',
  templateUrl: './card-ods.component.html',
  styleUrls: ['./card-ods.component.css']
})
export class CardOdsComponent implements OnInit {

  @Input() cardOdsData: any;
  @Input() cardOdsImage: any;
  @Input () cardOdsSelect: any;
  @Input () cardOdsCat: any;
  @Input () cardOdsRepo: any;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {}

  getSearch(select, cate, data, repo){
    this.router.navigate(['/busqueda/'+select+'/'+cate[0]+'/'+data +'/'+repo]);
    console.log("clic en card ods", select, cate, data, repo)
  }

}
