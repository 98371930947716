import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name:"slash_izquierda"
})
export class SlashIzquierdaPipe implements PipeTransform{

  transform(value:string):string {
    let splits = value.split('/');
    if(splits.length > 1) {
      return splits[0];
    } else {
      return '';
    }
  }

}