import { Component, OnInit } from '@angular/core';
import json                  from '../../../assets/json/datos-investigacion/statistics-datos-investigacion.json';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  public json: any = json;

  constructor() { }

  ngOnInit() {
  }

}
