import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name:"txt"
})
export class TxtPipe implements PipeTransform{

  transform(value:string):string {

    let splits = value.replace('.txt', '');

      return splits;
  }

}