import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';
import { QueriesService }           from './queries.service';

@Injectable()
export class FormdatosGuard implements CanActivate {

  constructor(
    private _queriesService: QueriesService,
    private _router: Router
  ){ }

  canActivate(){
        let identity = this._queriesService.getToken();
        
        if(identity){
            return true;
        }else{
            window.location.href='assets/php/cas-log/cas-login.php?ruta=upload/form';
            return false;
        }
  }
  
}
